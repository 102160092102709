.history_bar {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.5rem;
  background: #FFF;
  border-bottom: 1px solid var(--bright-border-color);

  .history_arrow {
    margin: 0 1rem;
  }
}

.page_wrapper {
  width: 100%;
  min-height: calc(100vh - 50px);
  padding: var(--container-base-padding-y) var(--container-base-padding-x);
}