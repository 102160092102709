
.main_layout_wrapper {
  height: 100%;
  overflow: hidden;
  width: 100%;
  min-width: 1300px;

  .top_nav_wrapper {
    position: fixed;
    left: 0;
    right: 0;
    height: 50px;
    z-index: 1001;
    border-bottom: 1px solid #e2e7f1;
    padding-right: 20px;

    .logo_box {
      float: left;
      width: 200px;
      height: 100%;
      max-height: 50px;
      border-right: 1px solid #e2e7f1;
      background-color: var(--global-home-color);
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 100px;
      }
    }

    .user_section {
      float: right;
      list-style: none;
      margin-top: 0;
      margin-bottom: 0;
      padding: 0;

      li {
        float: left;
        line-height: 50px;
        padding: 0 12px;

        svg {
          display: inline-block;
          vertical-align: middle;
        }

        p {
          margin: 0;
          font-size: 0.85rem;

          span {
            color: var(--global-home-color);
          }
        }
      }
    }
  }

  .left_side_wrapper {
    position: fixed;
    top: 50px;
    bottom: 0;
    width: 200px;
    border-right: 1px solid #e2e7f1;

    .side_menu {
      list-style: none;
      padding: 0 0 20px 0;

      li {
        a {
          text-decoration: none;
          padding: 12px 25px;
          transition: all .4s;
          font-size: 0.95rem;
          display: block;
          color: var(--base-black-txt-color);
          border-bottom: 1px solid var(--bright-border-color);


          span, svg {
            vertical-align: middle;
          }

          svg {
            margin-right: 10px;
          }

          &:hover, &.active {
            span, svg {
              color: var(--global-home-color);
              fill: var(--global-home-color);
            }
          }
        }


      }
    }
  }

  .content_container {
    margin-left: 200px;
    overflow: hidden;
    padding: 0 0 65px 0;
    min-height: calc(100vh - 50px);
    margin-top: 50px;
    background-color: #F3F4F7;

  }
}


.status_tab {
  display: flex;
  border-bottom: 1px solid var(--bright-border-color);

  li {
    list-style: none;

    button {
      background: none;
      border: none;
      padding: 0.2rem 1rem;
      color: var(--bright-txt-color);
      display: flex;
      flex-direction: row;
      align-items: flex-end;

    }

    &.active {
      button {
        border-bottom: 3px solid var(--global-home-color);
        color: var(--global-home-color);
      }
    }
  }
}


.center_bottom_btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 4rem;

  button {
    margin: 0 1.5rem;
    border: 1px solid var(--bright-border-color);
    padding: 0.4rem 2.8rem;

    &:first-child {
      background-color: var(--global-home-color);
      color: #FFF;
    }

    &:last-child {
      background-color: #FFF;
    }
  }
}

.flex_row_between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}