:root {
  --global-home-color: #59C4BC;
  --bright-border-color: #D9D9D9;
  --form-border-color: #ced4da;
  --bright-txt-color: #787878;
  --base-border-color: #c9c9c9;
  --base-black-txt-color: #444444;
}

.home_color {
  color: var(--home-bright-color);
}

.exist_not {
  color: red;
}


.exist_use {
  color: var(--home-bright-color);
}