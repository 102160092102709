

.login_page_wrapper {
  width: 100%;
  height: 100vh;
  position: relative;

  .login_box {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid var(--home-bright-color);
    width: 100%;
    max-width: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .to_register {
      width: 100%;
      text-align: right;
      margin-bottom: 16px;
      p {
        color: var(--bright-txt-color);
        a {
          margin-left: 6px;
          color: var(--home-bright-color);
          &:hover {
            color: var(--home-dark-color);
          }
        }
      }
    }

    .main_logo {
      padding: 1.5rem 0 2.5rem 0;

      img {
        fill: var(--home-bright-color);
        color: var(--home-bright-color);
        width: 150px;

      }
    }

    input {
      padding: 0.2rem 0.6rem;
      color: var(--bright-txt-color);
    }

    input, button {
      height: 38px;
      margin-bottom: 16px;
      border: 1px solid var(--form-border-color);

    }

    button, input {
      width: 100%;
    }

    button {
      cursor: pointer;
      padding: 0.2rem;
      background-color: var(--global-home-color);
      color: white;

      &:hover {
        opacity: 0.5;
      }
    }
  }
}