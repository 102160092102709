.user_manage_detail_wrapper, .sales_post_detail_wrapper, .qs_detail_wrapper {
  margin-top: 20px;

  .info_tab {
    padding: var(--container-base-padding-y) var(--container-base-padding-x);
    width: 100%;
    max-width: 800px;

    #modify_btn, #del_btn {
      align-items: center;
      background: #fff;
      border: 1px solid #fff;
      border-radius: 6px;
      box-shadow: 0 4px 12px rgb(0 0 0 / 10%);
      color: var(--global-home-color);
      display: flex;
      font-size: 12px;
      font-weight: 600;
      justify-content: center;
      margin-left: 1rem;
      min-width: 70px;
      padding: 0.6rem 0.8rem;

      &:hover {
        opacity: 0.5;
      }
    }

  }
}

.info_list {
  list-style: none;
  background-color: #FFF;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 5px;
  margin-bottom: 0;
  padding-left: 0;

  .row {
    margin: 0;
  }

  .info_name, .info_value {
    border-bottom: 1px solid #EEEEEE;
    color: var(--base-black-txt-color);
    font-size: 0.9rem;
  }

  .info_name {
    padding: 0.6rem 0;
    background-color: #D9D9D9;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .info_value {
    padding: 0.6rem 12px;

    .img_list_wrapper, .item_image_wrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      .close {
        background: none;
        border: none;
      }
    }

    .img_list_wrapper {
      p {
        width: 100%;
        display: flex;
        align-items: center;
        margin-bottom: 0;
      }
    }

    .item_image_wrapper {


      a {
        max-width: 100px;
        border: 1px solid var(--bright-border-color);
        padding: 0.25rem 0.2rem;
        border-radius: 30px;
        font-size: 0.8rem;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        margin-right: 0.5rem;
        color: var(--bright-txt-color);
        text-decoration: none;
      }
    }
  }

}

.user_add_page, .user_modify_page, .sales_post_modify_page, .sales_post_create_page {
  width: 100%;
  max-width: 800px;
  margin: 5rem auto 0;
}

.qs_detail_wrapper {
  #change_status_btn {
    background-color: #FFF;
    border: 1px solid var(--global-home-color);
    min-width: 80px;

    &:hover {
      background-color: var(--global-home-color);
      color: #FFF;
    }
  }
}

.close {
  display: inline-block;
  *display: inline;
}

.close:after {
  display: inline-block;
  content: "\00d7";
  font-size: 15pt;
}