.table_filter {
  margin-top: 20px;

  .filter_options {
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    padding: 0;

    & > li {
      width: 33%;
      display: flex;
      padding-right: 1rem;
      margin-bottom: 1rem;

      .option_name {
        flex: 0 0 80px;
        height: 35px;
        background-color: #d9d9d9;
        line-height: 35px;
        font-weight: 500;
        text-align: center;
        font-size: 0.875rem;
      }

      .form-group {
        flex: 1;
      }
    }
  }

  .filter_btns {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;

    button {
      margin: 0 1rem;
      padding: 0.4rem 2.8rem;
      border: 1px solid var(--bright-border-color);

      &#search_btn {
        background-color: var(--global-home-color);
        color: #FFF;
      }

      &#init_btn {
        background: #FFF;
        color: var(--base-black-txt-color);
      }

    }
  }
}

.paginate_wrapper {
  width: 100%;
  border-radius: 4px;
  background-color: #FFF;
  padding: 0.6rem 0;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);

  .paginate {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      margin: 0 0.5rem;
      padding: 0.2rem 0.6rem;
      color: #005F8E;

      &.selected {
        border: 1px solid var(--bright-border-color);
        color: var(--global-home-color);
      }

      a {
        text-decoration: none;
        color: #1cc1b7;
      }
    }
  }
}

.bottom_btn {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 2rem;

  button {
    margin-left: 1rem;
    color: #FFF;
    background-color: #8D8D8D;
    border-radius: 30px;
    padding: 0.5rem 2.2rem;
    border: 1px solid #444;
  }
}