.form-group {
  select {
    position: absolute !important;
    bottom: 0;
    left: 50%;
    display: block !important;
    width: 0.5px !important;
    height: 1px !important;
    padding: 0 !important;
    opacity: 0 !important;
    border: none;
    z-index: 0 !important;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    margin: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  .dropdown {
    padding: 0;
    border: none;
    height: 35px;
    background-color: #FFF;
  }

  .dropdown-menu {
    min-width: 100%;
    right: auto;
    bottom: auto;

    &.inner {
      position: static;
      float: none;
      border: 0;
      padding: 0;
      margin: 0;
      border-radius: 0;
      -webkit-box-shadow: none;
      box-shadow: none;

      .active {
        a {
          color: var(--global-home-color);
          background: #F4F5F9;
        }
      }

      a {
        cursor: pointer;
        font-size: 0.875rem;
        color: var(--bright-txt-color);
        background-color: #FFF !important;

        &:hover {
          color: var(--global-home-color);
          background: #F4F5F9 !important;
        }
      }
    }
  }


  .form_check {
    display: inline-flex;
    align-items: center;
    padding-left: 0;
    margin-right: 0.75rem;
    position: relative;

    .check_label_wrapper {
      cursor: pointer;
      margin-left: 5px;
      margin-top: 3px;
      margin-bottom: 0;
      display: block;
      position: relative;
      padding-left: 35px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      height: 25px;
      line-height: 25px;

      &.checked {
        color: #3f3f3f;
      }

      p {
        margin-left: 30px;
        margin-bottom: 10px !important;
      }

      &:hover input ~ .checkmark {
        background-color: #ccc;
      }
    }

    input:checked ~ .checkmark {
      background-color: var(--global-home-color);
    }

    input:checked ~ .checkmark:after {
      display: block;
    }

    input[type=checkbox] {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
      margin-right: 0.625rem;


    }

    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 25px;
      width: 25px;
      background-color: #eee;

      &:after {
        content: "";
        position: absolute;
        display: none;
        left: 9px;
        top: 5px;
        width: 5px;
        height: 9px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }
  }

  input[type=text], input[type=number], input[type=password] {
    height: 35px;
    border: 1px solid var(--bright-border-color);
    padding: 0 8px;
    width: 100%;
  }
}

.row_input_wrapper {
  position: relative;
  display: flex;
  align-items: stretch;
  width: 100%;

  .prepend, .prepend_sub {

    span {
      background: var(--bright-table-bg-color);
      min-width: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 0;
      font-size: 0.875rem;
      color: #5A7F71;
      height: var(--contract-row-height);
      border-left: 0.5px solid var(--contract-table-border-color);
      border-right: 0.5px solid var(--contract-table-border-color);
      border-bottom: 0.5px solid var(--contract-table-border-color);

    }
  }

  .form_input {
    input {
      height: var(--contract-row-height);
      border-top: 0.5px solid var(--contract-table-border-color);
      border-bottom: 0.5px solid var(--contract-table-border-color);
      border-left: none;
      border-right: none;
    }
  }
}

.file_uploader_base_wrapper {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;

  .uploader_prepend {
    margin-right: -1px;

    span {
      background: var(--home-dark-color);
      border: 1px solid transparent;
      min-width: 95px;
      display: flex;
      justify-content: center;
      padding: 0.532rem 0.75rem;
      border-radius: 0;
      color: white;
      font-size: 0.875rem;

    }
  }

  .custom_file {
    display: flex;
    align-items: center;
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0;

    input {
      cursor: pointer;
      position: relative;
      z-index: 2;
      width: 100%;
      height: calc(1.5em + 0.75rem + 2px);
      margin: 0;
      opacity: 0;
    }

    .custom-file-label {
      height: 100%;
      padding: 0.5rem 0.75rem;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      z-index: 1;
      font-weight: 400;
      line-height: 1.5;
      color: #495057;
      background-color: #fff;
      border: 1px solid #f0f1f5;
      border-radius: 0;

      &:after {
        background: #656C73;
        border: 1px solid #f0f1f5;
        color: #fff;
        border-radius: 0;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 3;
        display: block;
        height: 100%;
        padding: 0.375rem 0.75rem;
        line-height: 1.5;
        content: "업로드";
        border-left: inherit;
      }
    }

    .file_loader {
      border: 1px solid #f0f1f5;
      background-color: white;
      width: 100%;
      height: 40px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      .loader_spinner {
        width: 20px !important;
        height: 20px !important;
        color: var(--home-bright-color);
      }

      p {
        font-size: 0.75rem;
        color: #666;
        margin-left: 1rem;
      }
    }
  }
}

.select_toggle {
  height: 35px;
  border: 1px solid var(--bright-border-color) !important;
  width: 100%;
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(90 127 113)'><polygon points='0,0 100,0 50,50'/></svg>") no-repeat;
  background-size: 16px;
  background-position: calc(100% - 10px) 15px;
  background-repeat: no-repeat;
  background-color: #FFF;
  border-radius: 0 !important;

  &:focus-visible {
    outline-color: var(--global-home-color);
  }

  &:after {
    margin-left: 0;
  }

  .filter-option {
    position: static;
    top: 0;
    left: 0;
    float: left;
    height: 100%;
    width: 100%;
    text-align: left;
    overflow: hidden;
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;

    .filter-option-inner-inner {
      overflow: hidden;
    }
  }
}

.text_area_base_wrapper {
  textarea {
    width: 100%;
    border: 1px solid var(--bright-border-color);
    min-height: 200px;
    padding: 10px;

    &:focus-visible {
      outline-color: var(--home-bright-color);
    }
  }
}

.date_picker_base_wrapper {
  .date_picker_wrapper {
    border: 1px solid var(--bright-border-color);
    background-color: #FFF;
  }
}

#swal2-title {
  font-size: 24px;
}

.swal2-confirm {
  background-color: var(--global-home-color) !important;

  &:focus {
    box-shadow: 0 0 0 3px rgba(90, 127, 113, 0.48) !important;
  }
}

.file_uploader_wrapper {
  .dropzone {
    text-align: center;
    padding: 30px;
    border: 3px dashed #eeeeee;
    background-color: #fafafa;
    color: #bdbdbd;
    cursor: pointer;
    margin-bottom: 20px;
  }

  .selected-file-wrapper {
    text-align: center;
  }

  .selected-file {
    color: #000;
    font-weight: bold;
  }

  .list-group-item {
    color: var(--bright-txt-color);
    padding: 12px 0 !important;

    svg {
      color: var(--home-bright-color);
      margin-right: 0.4rem;
    }

  }
}

.space_between_txt {
  text-align: justify !important;
  text-align-last: justify;
}

.time_flow {
  line-height: 35px;
  text-align: center;
  font-weight: 400;
}


.with_label_box {
  display: flex;
  margin: 0.5rem 0;

  label {
    flex: 0 0 20%;
    background-color: #D9D9D9;
    text-align: center;
    line-height: 35px;
  }

  .form_content {
    flex: 0 0 80%;
  }
}

.MuiButtonBase-root{
  svg{
    fill: #8E8D8D;
  }
}

.filter-option-inner-inner{
  font-size: 0.875rem;
}